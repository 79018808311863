.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    opacity: 0;
    z-index: 2;
    transition: opacity 2s ease;
}

.active {
    opacity: 1;
}

.navigation {
    display: flex;
    flex-direction: column;
}

.button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.home_button_container {
    margin: 0.5rem 1rem;
}

.submenu {
    margin-left: 2rem;
}

.button {
    margin: 0.5rem 1rem;
    padding: 1rem;
    border: none;
    font-family: inherit;
    font-size: 10px;
    font-weight: bold;
    text-align: end;
    color: inherit;
    background-color: inherit;
    cursor: pointer;
}

.button>svg {
    width: 2rem;
    height: auto;
}

@media (min-width: 480px) {
    .button {
        font-size: 12px;
    }
}