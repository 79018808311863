.item {
    padding: 1rem;
    list-style-type: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.item a {
    color: var(--default-text-color);
    text-decoration: none;
}

.item:hover {
    text-decoration: underline;
}