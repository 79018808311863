/* .container {
    transform: rotate(90deg);
} */

.button {
    padding: 1rem;
    border: none;
    font-family: inherit;
    font-weight: bold;
    color: var(--default-text-color);
    background-color: inherit;
    cursor: pointer;
    transition: 0.3s ease; 
}

.button>svg {
    width: 1.75rem;
    height: auto;
}

@media (min-width: 1600px) {
    .container {
        margin-right: 8%;
    }
}

@media (min-width: 2048px) {
    .container {
        margin-right: 16%;
    }
} 