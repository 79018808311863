.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow: hidden;
    background-color: rgba(40, 40, 40, 0.5);
    border-radius: 1rem;
    border: 0;
    color: white;
    cursor: pointer;
    transition: all 0.1s;
}
.container:hover  {
    transform: scale(1.05);
}

.container:active  {
    transform: scale(0.95);
}

.logo {
    min-width: 4rem;
    min-height: 4rem;
}

.progress_band {
    position: absolute;
    top: 1rem;
    right: -2rem;    
    width: 8rem;    
    font-weight: bold;
    background-color: rgba(150, 0, 0, 0.8);
    transform: rotate(45deg);
}