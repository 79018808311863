.container {
    margin: 0 auto;
    min-height: 100vh;
    padding: 1rem;    
    text-align: center;
    background-color: #171717;
}

.content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image_container>svg {
    max-height: 12rem;
}

.button {
    margin-top: 1.5rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
    transition: 0.3s ease;    
}

.button>svg {
    width: 2.5rem;
    height: auto;
}