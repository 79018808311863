.container {
    position: relative;
    width: 100%;
    padding: 0 0.5rem 2rem 0.5rem;
}

.slider {
    margin: 1rem auto;
    width: 80%;
}

.slide {
    position: relative;
    padding: 0 0.5rem;
    opacity: 0.7;
    transition: opacity 0.3s ease;

}

.slide:hover {
    opacity: 1;
}

.image {
    width: 80%;
    margin: 2rem auto;
}

.button_learn_more {
    display: none;
}

:global .slick-arrow::before {
    color: var(--icon-stroke-color);
}

:global .slick-prev,
:global .slick-next {
    top: 44%;
}

:global .slick-dots {
    bottom: -1rem;
}

:global .slick-dots li button::before,
:global .slick-dots li.slick-active button::before {
    color: var(--icon-stroke-color);
}

@media (min-width: 400px) {
    .container {
        padding: 0 1rem 2rem 1rem;
        padding: 0 0.5rem 2rem 0.5rem;
    }

    .slider {
        width: 88%;
    }

    :global .slick-dots {
        bottom: 0;
    }
}

@media (min-width: 768px) {
    .slide {
        padding: 1rem;
    }

    .button_learn_more {
        display: inline-block;
        position: absolute;
        top: 64%;
        left: 10%;
        border: none;
        font-family: inherit;
        font-size: 1rem;
        font-weight: bold;
        color: var(--default-text-color);
        background-color: inherit;
        cursor: pointer;
    }

    :global .slick-dots {
        bottom: 2rem;
    }
}

@media (min-width: 1440px) {
    .slider {
        width: 72%;
    }

    .slide {
        padding: 2rem;
    }

    .button_learn_more {
        left: 11%;
    }

    :global .slick-dots {
        bottom: 4rem;
    }
}