.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.navigation_trick_element {
    position: absolute;
    top: -60px;
}

@media (min-width: 768px) {

    .container {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 2rem 1rem;
    }   

    .container>* {
        flex: 1 0 calc(50% - 2rem);
    }    
    
}