.container {
    position: relative;
    /* padding: 1rem 2rem;     */
    padding: 2rem;   
    border-radius: 1rem;
    text-align: center;
    box-shadow: 4px 4px 8px #000;        
    transition: background-color 0.3s ease;
}

.navigation_trick_element {
    position: absolute;
    top: -60px;
}

.heading {
    font-size: 1.75rem;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media (min-width: 768px) {
    .container {
        margin: 0 1rem;
    }    
}

@media (min-width: 1024px) {
    .container {
        flex: 1;
        max-width: 480px;
    }    
}

.info {
    margin-left: 0.5em;
    display: inline-block;
    height: 0.8em;
    width: 0.8em;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.1s ease;
}
.info:hover {
    opacity: 1;
}

.info svg {
    max-width: 100%;
    max-height: 100%;
}