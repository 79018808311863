.container_external {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;    
    transition: 0.5s ease;
}

.scrolled {
    background-color: rgba(17, 17, 17, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1;
}

.button {
    margin: 0.5rem 1rem;
    padding: 1rem;
    border: none;
    font-family: inherit;
    font-size: 10px;
    font-weight: bold;
    color: inherit;
    background-color: inherit;
    cursor: pointer;
    transition: 0.3s ease;
}

.button>svg {
    width: 2rem;
    height: auto;
}

.scrolled .button {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
}

@media (min-width: 480px) {
    .button {
        font-size: 12px;
    }
}