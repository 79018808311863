.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 240px;
    background-color: #111;
    background-image: linear-gradient(255deg,
            rgba(0, 0, 0, 0),
            rgba(225, 230, 35, 0.12) 20%,
            rgba(0, 0, 0, 0),
            rgba(255, 105, 180, 0.14) 70%,
            rgba(0, 0, 0, 0),
            rgba(0, 180, 200, 0.2));
}

.heading {
    margin-top: auto;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    font-size: 2rem;
    font-weight: 300;
    color: #ccc;
}

@media (min-width: 480px) {
    .container {
        min-height: 360px;
    }

    .heading {
        font-size: 2rem;
    }

}

@media (min-width: 768px) {
    .container {
        min-height: 480px;
        animation: gradientAnimation 10s ease-out alternate infinite;
    }

    .heading {
        font-size: 3rem;
    }

    @keyframes gradientAnimation {
        0% {
            background-size: 200% 100%;
        }

        100% {
            background-size: 100% 100%;
        }
    }
}