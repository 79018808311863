.container_external {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    transition: padding 0.3s ease;
}

.scrolled {
    padding: 0 2rem;
    background-color: rgba(17, 17, 17, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1;
}

.list {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1600px) {
    .container {
        width: 28%;
    }
}