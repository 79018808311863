.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.heading {
    padding: 1rem;
    font-size: 1.75rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: none;
    font-family: inherit;
    font-weight: bold;
    color: inherit;
    background-color: inherit;
    cursor: pointer;
}

.button svg {
    width: 3rem;
}


@media (min-width: 1024px) {
    .heading {
        font-size: 2.5rem;
    }
}