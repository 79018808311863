.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin: 1rem 0;
}

@media (min-width: 768px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1400px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 2rem;
    }
}