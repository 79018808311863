@import-normalize;

:root {
    /* --mobile-landscape-breakpoint: 480px;
    --tablet-breakpoint: 768px;
    --desktop-breakpoint: 1024px; */

    --default-background-color: #111;
    --light-background-color: #fff;
    --heading-text-color: #ddd;
    --default-text-color: #ccc;
    --icon-stroke-color: #ccc;
}

html {
    box-sizing: border-box;
    margin: 0;
    scrollbar-gutter: stable;
    font-family: "Inter", system-ui, "Roboto", Helvetica, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: var(--default-text-color);
    background-color: var(--default-background-color);
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    min-height: 100vh;
}

/* body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dd, ol, ul, form, fieldset, legend, figure, table, th, td, caption, hr {
    margin: 0;
    padding: 0;
} */

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    color: var(--heading-text-color);
}

ol, ul {
    margin: 0;
    padding: 0;
}

@media (min-width: 400px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1024px) {
    html {
        font-size: 16px;
    }
}