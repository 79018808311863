.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    background-color: rgba(40, 40, 40, 0.5);    
    border-radius: 1rem;
    opacity: 0.8;    
}

.icon_container>svg {
    padding: 1rem;
    width: 7rem;
    height: auto;
}

.text {
    padding: 0 1rem;
}

@media (min-width: 1024px) {

    .text {
        margin-top: 0;
        padding: 0;
    }
}

@media (min-width: 1400px) {
    
    .container {
        flex-direction: row;
    }
}