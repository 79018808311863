.container {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    padding: 1rem;
    max-width: 720px;
}

.heading {
    margin: 1rem;
}

.image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image_container>img {    
    margin: 1rem;
    width: 80%;
}

.text_container {
    margin: 1rem;
}

.pill_container {
    display: flex;
}

.list>li {
    margin-left: 1rem;
}